<template>
	<div class="teacher">
		<Ikns title="知识点基础信息">
			<el-form ref="dataForm" :model="formData" :rules="rules" label-position="left" label-width="100px">
				<el-form-item label="所属学科" prop="subject_id">
					<el-select v-model="formData.subject_id" placeholder="请选择所属学科" @change="setRootName">
						<el-option v-for="i in subjectData" :label="i.subject_name" :value="i.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="应用学段" prop="apply_type">
					<el-radio-group v-model="formData.apply_type">
						<el-radio v-for="i in applyType" :label="i.id">{{ i.name }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
		</Ikns>

		<Ikns style="margin-top: 24rem" title="知识点内容">
      <editable-tree
          ref="tree"
          allow-empty
          node-default="知识点"
          :max-level="4"
          :max-name-length="150"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :level-rule="levelRule"
          :props="defaultProps"
          :data="data">

      </editable-tree>

		</Ikns>

		<div style="margin-top: 40px">
			<el-button type="primary" @click="onSubmit">保存</el-button>
			<el-button @click="goBack">返回</el-button>
		</div>
	</div>
</template>

<script>
import EditableTree from "@/components/EditableTree";
import Ikns from "@/views/research/textbook/knowledge-point-management/Ikns.vue";
import {parseData} from "utils/l-tools";

let key = 1;

export default {
	_config: {"route": {"path": "add", "meta": {"title": "新增"}}},
	components: {Ikns,EditableTree},
	data() {
		return {

      //章节内容数据
      data:[
        {
          knowledge_name: '知识点',
          key:this.$tools.GUID(),
          knowledge_list: []
        }
      ],
      defaultProps:{
        label:"knowledge_name",
        children:"knowledge_list"
      },
      levelRule:[
        // 一级子树
        {
          editable:false, // 是否可编辑
          onlyDeleteChild:true, // 是否只删除子节点
          maxLength:50,    // 子节点最大长度, 设为-1 表示不限制
        },
        // 二级子树
        {
          maxLength: 10
        },
        // 三级子树
        {
          maxLength: 5
        },

      ],
			formData: {
				subject_id: "",
				apply_type: "",
				knowledge_list: [],
			},
			applyType: [],
			subjectData: [],
			rules: {
				subject_id: [
					{required: true, message: "请选择所属学科", trigger: "change"},
				],
				apply_type: [
					{required: true, message: "请选择应用学段", trigger: "change"},
				],
			},
			// data: JSON.parse(JSON.stringify(data)),
			mes: "",
			editId: null,
		};
	},
  watch:{

  },
	methods: {
    setRootName(){
      let subject = (this.subjectData.find(item => item.id === this.formData.subject_id) || {}).subject_name||"";
      subject = subject ? `${subject}-` : "";
      this.data[0][this.defaultProps.label] = `${subject} 知识点`
    },
		replaceSubstring(originalString, searchString, replacementString) {
			return JSON.parse(JSON.stringify(originalString).replace(new RegExp(searchString, "g"), replacementString));
		},
		onSubmit() {
      this.$refs.dataForm.validate(valid => {
				if (valid) {
          this.formData.knowledge_list = this.data;
					this.$api2.post("api/video/add-knowledge", this.formData, {logic: 1}).then(res => {
						this.$message.success("新增成功");
						this.$router.back();
						this.$store.commit("setPage", 1);
					});
				}
			});
		},
		goBack() {
			this.$router.go(-1);
		},

    // 判断节点能否被放置
    allowDrop(draggingNode, dropNode, type) {
      let curParentId = draggingNode.parent.id;
      let parentId = dropNode.parent.id;
      return parentId === curParentId && type !== 'inner';
    },
    // 判断节点能否被拖拽
    allowDrag(draggingNode) {
      return draggingNode.level !== 1;
    },
	},
	created() {
		this.$api2.get("api/video/search-knowledge").then(res => {
			this.applyType = parseData(res);
		});
		this.$api2.get("api/common/subject?level=1").then(res => {
			this.subjectData = parseData(res);
		});
	},
};
</script>

<style lang="scss" scoped>
.teacher {
	background: #F7F7F7 !important;
}

::v-deep .el-tree-node__content {
	height: 40rem !important;
}
</style>
